import routes from '~/assets/javascript/constants/routes';
import AbstractBuilder from '../AbstractBuilder';

export class SheetItemBuilder extends AbstractBuilder {
  constructor({ localeRoute, $route, currentEnvironment }) {
    super({
      localeRoute,
      $route,
      currentEnvironment,
      i18nMessages: {
        en: { label: 'Search result - Sheet {name}' },
        pt: { label: 'Resultado da busca - Base de dados {name}' },
      },
      type: 'sheet',
    });
  }

  itemRoute = sheet => ({
    name: routes.studio.sheets.routeName,
    params: { tenantSlug: this.$route.params.tenantSlug, [routes.studio.sheets.sheetId]: sheet.id },
  });
}
