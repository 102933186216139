<i18n lang="yaml">
pt:
  search: 'Encontrar página'
en:
  search: 'Find page'
</i18n>

<template>
  <deck-tooltip
    :disabled="expanded"
    position="bottom"
  >
    <template #activator="{ props }">
      <deck-text-field
        :aria-label="placeholder"
        hide-details
        :placeholder="placeholder"
        bg-color="var(--z-input-background-color)"
        color="#a0a0a0"
        class="the-topbar-search-text-input js-the-topbar-search-text-input"
        :class="{ 'the-topbar-search-text-input--expanded': expanded }"
        :prepend-icon="{
          name: 'search',
          kind: 'regular',
          color: 'grey',
        }"
        :append-icon="expanded ? 'fa-times fa-regular' : ''"
        :model-value="modelValue"
        v-bind="props"
        @focus="emitFocus"
        @click="emitClick"
        @click:append="$emit('close')"
        @update:model-value="$event => $emit('update:modelValue', $event)"
        @keydown="textInputKeydown"
      />
    </template>

    <template #content>
      <deck-kbd
        command="cmd+k"
        dark
      />
    </template>
  </deck-tooltip>
</template>

<script>
import { defineComponent } from 'vue';
import { isMac } from '~/assets/javascript/utils';

export default defineComponent({
  name: 'TheTopbarSearchTextInput',
  props: {
    modelValue: { type: String, default: '' },
    expanded: { type: Boolean, required: true },
    placeholder: { type: String, default: null },
  },
  emits: ['close', 'focus', 'click', 'keyPressed', 'update:modelValue'],
  computed: {
    isMac() {
      return isMac();
    },
  },
  watch: {
    expanded(value) {
      if (!value) return;

      document.querySelector('.js-the-topbar-search-text-input input:first-of-type').focus();
    },
  },
  methods: {
    emitFocus() {
      this.$emit('focus');
    },
    emitClick() {
      this.$emit('click');
    },
    textInputKeydown($event) {
      if ($event.key === 'Escape') {
        this.$emit('keyPressed', $event);
        return;
      }

      const commandAndIsMac = $event.metaKey && this.isMac;
      const controlAndIsNotMac = $event.ctrlKey && !this.isMac;

      if ($event.key === 'k' && (commandAndIsMac || controlAndIsNotMac)) {
        this.$emit('keyPressed', $event);
        return;
      }

      if (['ArrowDown', 'ArrowUp', 'Tab'].includes($event.key)) {
        this.$emit('keyPressed', $event);
      }
    },
  },
});
</script>

<style lang="scss">
.the-topbar-search-text-input {
  --append-icon-width: 28px; // 24px icon + 4px padding-left of the .v-input__append-inner

  input {
    font-size: 16;
    max-height: var(--search-height);

    &::placeholder {
        text-align: center;
    }
  }

  .v-field__append-inner,
  .v-field__prepend-inner {
    align-self: center !important;
    margin: 0 !important;

    .v-icon {
      font-size: 18px !important;
    }
  }

  .v-select__slot,
  .v-field__field {
    height: var(--search-height) !important;
    transition: var(--search-transition);
    max-height: unset !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 4px !important;

    @media (min-width: 600px) {
      padding: 0 12px !important;
    }

    &::before,
    &::after {
      border: none !important;
    }

    fieldset {
      border: 1px solid #a0a0a0;
    }

    input {
      max-height: unset !important; // v-input override
      height: calc(var(--search-height) - var(--search-border-width) * 2);
      transition: var(--search-transition);

      &[placeholder] {
        text-overflow: ellipsis;
      }

      &::placeholder {
        font-size: 14px;

        @media (min-width: 600px) {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}

.the-topbar-search-text-input--expanded  {
  --append-icon-width: 0; // reset the simulated presence from the append icon
}
</style>
