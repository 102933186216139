export class SearchableItemBuilder {
  constructor(builder) {
    this.builder = builder;
  }

  buildItems = items => items.map(this.buildItem).filter(Boolean);

  buildItem = item => this.builder.buildItem(item);
}

export * from './builders';
