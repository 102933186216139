import routes from '~/assets/javascript/constants/routes';
import AbstractBuilder from '../AbstractBuilder';

export class WorkflowItemBuilder extends AbstractBuilder {
  constructor({ localeRoute, $route, currentEnvironment }) {
    super({
      localeRoute,
      $route,
      currentEnvironment,
      i18nMessages: {
        en: { label: 'Search result - Automation {name}' },
        pt: { label: 'Resultado da busca - Automação {name}' },
      },
      type: 'workflow',
    });
  }

  itemRoute = workflow => ({
    name: routes.studio.workflow.routeName,
    params: { tenantSlug: this.$route.params.tenantSlug, [routes.studio.workflow.workflowId]: workflow.id },
  });
}
