<i18n lang="yaml">
pt:
  empty: 'Nenhum resultado encontrado'
  views: 'Páginas'
  sheets: 'Bases de dados'
  workflows: 'Automações'
en:
  empty: 'No matching results'
  views: 'Pages'
  sheets: 'Sheets'
  workflows: 'Workflows'
</i18n>

<template>
  <div
    v-if="!emptyResult"
  >
    <SearchableItemsList
      v-for="(items, type) in validResult"
      :key="type"
      :subheader="t(type) || null"
      :items="items"
      :truncate-at="3"
      @item-clicked="$emit('itemClicked')"
    />
  </div>
  <v-list
    v-else
    density="compact"
  >
    <v-list-subheader>
      {{ t('empty') }}
    </v-list-subheader>
  </v-list>
</template>

<script>
import SearchableItemsList from '../_searchable-items-list';

export default defineComponent({
  name: 'TheTopbarSearchResult',
  components: {
    SearchableItemsList,
  },
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['itemClicked'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  computed: {
    validResult() {
      const emptyResultKeys = Object.keys(this.result).filter(this.emptyResultForKey);
      return _omit(this.result, emptyResultKeys);
    },
    emptyResult() {
      return _isEmpty(this.validResult);
    },
  },
  methods: {
    emptyResultForKey(key) {
      return _isEmpty(this.result) || _isEmpty(this.result[key]);
    },
  },
});
</script>
